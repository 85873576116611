import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';
import Brand from './Brand';
import IdecentralizeIntro from './Pitch';
import Introduction from "./Introduction";

const FlexContainer = styled.div`
    position: relative;
    top: 44px;
    display: block;
    min-height: calc(100vh - 44px);
`;

const UpperContainer = styled(Container)`
    background-color: #000000;
    padding: 20px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
`;

const MiddleContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 50%;
    transform: translateY(-50%);
    flex-grow: 1;
    display: flex;
    background-image: url(./fibe.jpeg);
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    color: #ffffff;
`;

const LowerContainer = styled(Container)`
    background-color: #000000; /* Example background color */
    padding: 20px; /* Example padding */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content at the top */
    justify-content: flex-start; /* Align container at the top */
    color: #ffffff;
`;

const VerticalCenteredImage = ({imageUrl, altText}) => {

    useEffect(() => {
    }, []);

    return (
        <FlexContainer id="home">
            <UpperContainer>
                <Brand/>
            </UpperContainer>
            <MiddleContainer id="middle-container">
                <Introduction/>
            </MiddleContainer>
            <LowerContainer>
                <IdecentralizeIntro/>
            </LowerContainer>
        </FlexContainer>
    );
};

export default VerticalCenteredImage;
