import React from 'react';
import styled from 'styled-components';

const ServiceContainer = styled.div`
    position: relative;
    top: 44px;
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
    height: calc(100vh - 44px);
`;

const ServiceTitle = styled.h2`
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
`;

const ServiceDescription = styled.p`
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.6;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
`;

const ServiceList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
`;

const ServiceItem = styled.li`
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 15px;
    line-height: 1.6;
`;

const ContactButton = styled.button`
    background-color: #4a90e2;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s;

    &:hover {
        background-color: #357ab8;
    }
`;

const ServiceSection = () => {
    return (
        <ServiceContainer id="services">
            <ServiceTitle>Tailored Blockchain Solutions</ServiceTitle>
            <ServiceDescription>
                We understand that everyone has their own vision. Contact us for a solution that fits your specific
                requirements. Whether you’re looking for a decentralized application or a scalable cloud platform, we
                are here to help.
            </ServiceDescription>

            <ServiceList>
                <ServiceItem>
                    Development of blockchain solutions, from decentralized applications to scalable cloud platforms.
                </ServiceItem>
                <ServiceItem>
                    Integration of blockchain into existing products, enhancing their functionality and security.
                </ServiceItem>
                <ServiceItem>
                    Transparency and reliability, with high standards in mind, whether it's a financial product or aimed
                    at the gaming industry.
                </ServiceItem>
            </ServiceList>

            <ContactButton>Contact Us for a Custom Solution</ContactButton>
        </ServiceContainer>
    );
};

export default ServiceSection;
