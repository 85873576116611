import React from "react";
import {Link as RouterLink} from "react-router-dom";
import styled from 'styled-components';

const ScrollLink = ({to, targetId, ...props}) => {
    const handleClick = (event) => {
        event.preventDefault();

        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            // You can adjust the behavior by changing the 'behavior' property
            window.scrollTo({
                top: targetElement.offsetTop - 44,
                behavior: "smooth",
            });
        }
    };

    return <NavLink to={to} onClick={handleClick} {...props} />;
};

const NavLink = styled(RouterLink)`
    text-decoration: none;
    margin: 10px;
    color: #ffffff;
    text-transform: uppercase;

    &:hover {
        text-decoration: none;
        color: #823494;
    }
`;
export default ScrollLink;
