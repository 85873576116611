import styled, { keyframes } from 'styled-components';

const StyledH1 = ({text}) =>{
    return(
    <MovingLightText>{text}</MovingLightText>
    )
}

const glowAnimation = keyframes`
  0% {
    text-shadow: 0 0 20px rgba(183, 105, 199, 0.9);
  }
  50% {
    text-shadow: 0 0 30px rgba(183, 105, 199, 1);
  }
  100% {
    text-shadow: 0 0 20px rgba(183, 105, 199, 0.9);
  }
`;

const MovingLightText = styled.h1`
  font-size: 2em;
  text-align: center;
  color: #ffffff; /* Set your desired text color */
  margin: 0;
  text-transform: uppercase;
    
  /* Glowing animation */
  animation: ${glowAnimation} 2s infinite;
`;
export default StyledH1;
