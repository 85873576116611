import React from 'react';
import styled from 'styled-components';

const IdecentralizeDescription = styled.div`
    text-align: justify;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
`;

// Styled component for the logos container
const LogoContainer = styled.div`
  display: flex;
  justify-content: center; // Center logos horizontally
  flex-wrap: wrap; // Allow logos to wrap to the next line
  gap: 20px; // Space between logos
  max-width: 800px;
  margin: 20px auto; // Center the container
`;

const Logo = styled.img`
  width: 100px; // Set a fixed size for all logos
  height: auto; // Maintain aspect ratio
  object-fit: contain; // Ensure logos are contained within their dimensions without stretching
`;

const logos = [
    '/docker-logo-blue.svg',
    '/ipfs.png',
    '/hyperledger.webp',
    '/openapis.svg'
    // Add as many logos as you need
];

const IdecentralizeIntro = () => {
  return (
    <IdecentralizeDescription>
        <LogoContainer>
            {logos.map((logo, index) => (
                <Logo key={index} src={logo} alt={`Logo ${index + 1}`} />
            ))}
        </LogoContainer>
    </IdecentralizeDescription>
  );
};

export default IdecentralizeIntro;
