import React from "react";
import styled from "styled-components";
import {Container, Navbar, Nav} from "react-bootstrap"
import ScrollLink from "./ScrollLink";

const Navigator = () => {
    return (
        <NavigatorWrapper>
            <StyledNavbar bg="#0c0627" variant="dark" expand="lg">
                <StyledContainer>
                    <Navbar.Brand>
                        <ScrollLink to="/home" targetId="home">
                            IDECENTRALIZE
                        </ScrollLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <ScrollLink to="/features" targetId="features">
                                features
                            </ScrollLink>
                            <ScrollLink to="/services" targetId="services">
                                services
                            </ScrollLink>
                            <ScrollLink to="/contact" targetId="contact">
                                contact
                            </ScrollLink>
                        </Nav>
                    </Navbar.Collapse>
                </StyledContainer>
            </StyledNavbar>
        </NavigatorWrapper>
    );
};

export default Navigator;

const StyledNavbar = styled(Navbar)`
    padding: 0 !important;
    background-color: #000000;
    text-align: center;
`;

const StyledContainer = styled(Container)`
    margin: auto;
`;

const NavigatorWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    left: 0;
    text-align: center;
    height: 44px;
    font-weight: 500;
    color: #ffffff;
    z-index: 10;
`;

