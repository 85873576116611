import React, {useState} from 'react';
import styled from 'styled-components';


const ContactContainer = styled.div`
    position: relative;
    top: 44px;
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
    height: calc(100vh - 44px);
`;
const ContactSection = styled.div`
    width: 100%;
    max-width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: auto;
`;

const ContactTitle = styled.h2`
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
`;

const ContactText = styled.p`
    font-size: 1rem;
    color: #555;
`;

const RevealButton = styled.button`
    background-color: #4a90e2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;

    &:hover {
        background-color: #357ab8;
    }
`;

const PhoneRevealComponent = () => {
    const [showNumber, setShowNumber] = useState(false);

    const handleToggle = () => {
        setShowNumber(!showNumber);
    };

    return (
        <ContactContainer id="contact">
            <ContactSection>
                <ContactTitle>Contact Me</ContactTitle>
                <ContactText>Email: ian@idecentralize.finance</ContactText>
                <ContactText>
                    Phone: {showNumber ? "(250) 777-7144" : "••• ••• ••••"}
                </ContactText>
                <RevealButton onClick={handleToggle}>
                    {showNumber ? "Hide Number" : "Show Number"}
                </RevealButton>
            </ContactSection>
        </ContactContainer>
    );
};

export default PhoneRevealComponent;
