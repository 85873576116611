import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import VerticalCenteredImage from './components/main/VerticalCenteredImage';
import Navigator from './components/main/Navigator';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer} from "react-toastify";
import ServiceSection from "./components/pages/services/Services";
import Features from "./components/pages/features/Features";
import Contact from "./components/pages/contact/Contact";

function App() {
  const shouldLoad = useRef(true)
  const imageUrl = './fibe.jpeg';
  const altText = 'Your Alt Text';
  useEffect(() => {
    (async () => {
      if (shouldLoad.current) {
        shouldLoad.current = false
      }
    })()
  }, []);
  return (
    <>
      <Router>
        <Navigator />
        <ToastContainer />
        <VerticalCenteredImage imageUrl={imageUrl} altText={altText} title="idecentralize"/>
        <Features/>
        <ServiceSection/>
        <Contact/>
      </Router>

    </>
  );
}

export default App;
