import React from 'react';
import styled from 'styled-components';
import {Container} from 'react-bootstrap';

const IntroductionContainer = styled(Container)`
    margin: 20px;
    padding: 20px;
    background-color: rgba(21, 21, 21, 0.5);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h1`
    font-size: 2rem;
    color: #dcdcdc;
    margin-bottom: 20px;
`;

const Paragraph = styled.p`
    font-size: 1.2rem;
    color: #ffffff;
    line-height: 1.6;
    margin-bottom: 15px;
`;

const Strong = styled.strong`
    color: #b8b6b6;
`;

const Introduction = () => {
    return (
        <IntroductionContainer>
            <Heading>Welcome to idecentralize.finance</Heading>
            <Paragraph>
                At <Strong>idecentralize.finance</Strong>, we offer innovative solutions for individuals and businesses eager to harness the power of blockchain technologies. Blockchain, and more specifically decentralized finance (DeFi), presents a groundbreaking alternative to the traditional banking system, unlocking new opportunities and reshaping the financial landscape.
            </Paragraph>
            <Paragraph>
                These revolutionary technologies have the potential to transform society on a global scale—so much so that they could one day govern entire countries. At <Strong>idecentralize.finance</Strong>, we provide tailored solutions for everyone, from those just beginning their journey to full-scale integration, helping you explore and implement these cutting-edge technologies that are set to revolutionize humanity.
            </Paragraph>
        </IntroductionContainer>
    );
};

export default Introduction;
